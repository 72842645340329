import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useMediaQuery, useTheme } from '@material-ui/core';

// @material-ui/icons
import Chat from "@material-ui/icons/Chat";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import Parallax from "components/Parallax/Parallax.js";

import styles from "assets/jss/material-kit-pro-react/views/landingPageSections/productStyle.js";

import './SectionApresentation.css'

const useStyles = makeStyles(styles);
const useStyles2 = makeStyles(() => ({
  fontP: {
    fontSize: "18px",
  },
}));

export default function ApresentationSection() {
  const classes = useStyles();
  const classes2 = useStyles2();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <div className={classes.section}>
      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <InfoArea
            title="Apresentação"
            icon={Chat}
            iconColor="primary"
            vertical
            styleDescription={classes2.fontP}
          />

          <p className="font">Advogada especialista em Direito Cível, formada em Direito pela Universidade do Vale do Itajaí, com 13 anos de experiência no mundo jurídico. Pós graduada em Direito Civil e processo Civil, Direito de Família, Direito Empresarial e Contratos e Direito Administrativo.</p>
          <p className="font">Iniciou a carreira na área jurídica no direito público e eleitoral, em seguida atuou como associada no Escritório de Advocacia e Assessoria Jurídica Lucena & Amodio na cidade de Jaraguá do Sul.
          </p>
          <p className='font'>Em toda trajetória no ramo do direito, protagonizou mais de 360 processos judiciais e atua com uma carteira de 50 clientes, em várias áreas. Atua em 5 cidades, Curitiba- PR, Jaraguá do Sul, Balneário Camboriú, Itapema, Barra Velha e Joinville-SC.</p>
          <p className="font">Presta serviço de mentoria, como consultora de outros advogados na área empresarial e família, elabora diagnósticos de processos, assessoria à empresas que necessitam de uma visão jurídica com um programa de regularização jurídica.
          </p>
          <p className="font">Atualmente com escritório aconchegante e acolhedor sempre priorizando a composição e agindo na prevenção. Assessorando com maestria empresários, consumidores, famílias em busca da justiça.
          </p>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <Parallax
            transformProps={isMobile ? false : true}
            image={require("assets/img/novas-imagens/3.jpg")}
          />
        </GridItem>
      </GridContainer>
    </div>
  );
}
