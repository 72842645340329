/*eslint-disable*/
import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";

import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-pro-react/components/headerLinksStyle.js";

import * as Styles from './styles';

const useStyles = makeStyles(styles);

export default function HeaderLinks({
  rollPageOnClickButton
}) {
  const classes = useStyles();

  const handleWhatsapp = () => {
    window.open('https://wa.me/554792301776?text=Olá,%20Carol%20gostaria%20de%20informações%20sobre%20seu%20trabalho', '_blank');
  }

  return (
    <Styles.Container>
      <List className={classes.list}>
        <ListItem className={classes.listItem}>
          <Button
            className={classes.navLink}
            color="transparent"
            onClick={() => rollPageOnClickButton('home')}
          >
            Home
          </Button>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Button
            className={classes.navLink}
            color="transparent"
            onClick={() => rollPageOnClickButton('apresentation')}
          >
            Apresentação
          </Button>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Button
            color="transparent"
            className={classes.navLink}
            onClick={() => rollPageOnClickButton('skills')}
          >
            Atuação
          </Button>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Button
            color="transparent"
            className={classes.navLink}
            onClick={() => rollPageOnClickButton('partners')}
          >
            Parcerias
          </Button>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Button
            color="transparent"
            className={classes.navLink}
            onClick={() => rollPageOnClickButton('location')}
          >
            Localização
          </Button>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Button
            color="transparent"
            className={classes.navLink}
            onClick={() => rollPageOnClickButton('contact')}
          >
            Contato
          </Button>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Styles.WrapperIcons>
            <Tooltip
              id="instagram-tooltip"
              title="Seguir no Instagram"
              placement={window.innerWidth > 959 ? "top" : "left"}
              classes={{ tooltip: classes.tooltip }}
            >
              <Button
                color="transparent"
                href="https://www.instagram.com/ana_carolina_lucena"
                target="_blank"
                className={classes.navLink}
              >
                <i className={classes.socialIcons + " fab fa-instagram"} />
              </Button>
            </Tooltip>
          </Styles.WrapperIcons>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Styles.WrapperIcons>
            <Tooltip
              id="instagram-tooltip"
              title="Entrar em contato WhatsApp"
              placement={window.innerWidth > 959 ? "top" : "left"}
              classes={{ tooltip: classes.tooltip }}
            >
              <Button
                color="transparent"
                onClick={handleWhatsapp}
                rel="noopener noreferrer"
                className={classes.navLink}
              >
                <i className={classes.socialIcons + " fab fa-whatsapp"} /></Button>
            </Tooltip>
          </Styles.WrapperIcons>
        </ListItem>
      </List>
    </Styles.Container>
  );
}
