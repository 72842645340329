import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import GroupIcon from "@material-ui/icons/Group";
import BusinessIcon from "@material-ui/icons/Business";
import { Info, PenTool } from "react-feather";
import GavelIcon from "@material-ui/icons/Gavel";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import styles from "assets/jss/material-kit-pro-react/views/landingPageSections/productStyle.js";

import './SectionApresentation.css';

const useStyles = makeStyles(styles);
const useStyles2 = makeStyles(() => ({
  fontP: {
    fontSize: "18px",
  },
}));

export default function SkillsSection() {
  const classes = useStyles();
  const classes2 = useStyles2();

  return (
    <div className={classes.section}>
      <div>
        <h2 className={classes.title}> Atuação </h2>

        <GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            <InfoArea
              title="Cível"
              description="Sempre no ramo do direito civil, tem no portfólio ações indenizatórias, possessórias como usucapião e reintegração de posse, regularização de áreas, em contratos com elaboração, analise e execuções de termos. Efetua periodicamente defesas jurídicas nos mais diversos seguimentos, haja vista a parceria com outros advogados especialistas e contabilizando inúmeros casos de sucesso."
              // iconSvg={Logo}
              icon={GavelIcon}
              iconColor="info"
              vertical
              styleDescription={classes2.fontP}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <InfoArea
              title="Família"
              description="Assessoria em Direito de Família
              Devido a longa experiência no direito de família, atua com maestria na composição de divórcios, dissoluções de união estável, partilha de bens, alimentos e guarda, priorizando pela justa composição entre o acordo e o litígio.
              Com um escritório aconchegante, uma visão contemporânea, acolhe as partes em um atendimento humanizado."
              icon={GroupIcon}
              iconColor="success"
              vertical
              styleDescription={classes2.fontP}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <InfoArea
              title="Empresarial"
              description="Assessoria à Revenda de Veículos Multimarcas
              Atuando a 4 anos com expertise em assessoria preventiva à revenda de veículos multimarcas, elaboração de contratos, assessoria ao direito de consumo voltado ao direito do empresarial. 
              Assessorando mais de 13 empresas de veículos multimarcas, em Joinville Jaraguá do Sul, visando a prevenção de processos, acompanhamento processual, elaboração de contratos, assessoria na garantia e direito do consumidor.
              "
              icon={BusinessIcon}
              iconColor="danger"
              vertical
              styleDescription={classes2.fontP}
            />
          </GridItem>
        </GridContainer>
        <div
          className="video"
          style={{
            position: "relative",
            paddingBottom: "56.25%" /* 16:9 */,
            paddingTop: 50,
            height: 0,
            marginTop: "50px",
          }}
        >
          <iframe
            src="https://www.youtube.com/embed/LJj5LHWdL2c?autoplay=1"
            frameBorder="0"
            allow="autoplay; encrypted-media"
            allowFullScreen
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
          />
        </div>
      </div>
    </div>
  );
}
