import React, { useState } from "react";
// import { useDispatch } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import * as emailjs from "emailjs-com";
// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import styles from "assets/jss/material-kit-pro-react/views/landingPageSections/workStyle.js";

import GlobalMessage from "components/GlobalMessage";

const useStyles = makeStyles(styles);

export default function ContactSection() {
  const classes = useStyles();
  const initialForm = { name: "", email: "", message: "", phone: "" };
  const [form, setForm] = useState(initialForm);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [message, setMessage] = useState({ message: "", severity: '' });

  const handleChange = (e) => {
    const { id, value } = e.target;
    
    setForm({ ...form, [id]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { message, name, email } = form;

    if (message !== "" && name !== "" && email !== "") {
      let templateParams = {
        from_name: name,
        to_name: "Ana Carolina Lucena",
        message_html: message,
      };
      let request = await emailjs.send(
        "gmail",
        "template_eWZVwsbZ",
        templateParams,
        "bEMzl3KN6UD5_fbjD"
      );

      if (request.status === 200) {
        setSnackbarOpen(true);
        setMessage({ message: "Email enviado com sucesso.", severity: 'success' });
        resetForm();
      }
    } else {
      setSnackbarOpen(true);
      setMessage({ message: "Preencha os campos nome, email e mensagem.", severity: 'error' });
    }
  };

  const resetForm = () => {
    setForm(initialForm);
  };

  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem cs={12} sm={12} md={8}>
          <h2 className={classes.title}> Contato </h2>

          <form id="contact-form" onSubmit={handleSubmit} method="POST">
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  onChange={handleChange}
                  labelText="Nome"
                  id="name"
                  value={form.name}
                  formControlProps={{
                    fullWidth: true,
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  onChange={handleChange}
                  labelText="Email"
                  id="email"
                  value={form.email}
                  formControlProps={{
                    fullWidth: true,
                  }}
                />
              </GridItem>
              <CustomInput
                onChange={handleChange}
                labelText="Mensagem"
                id="message"
                value={form.message}
                formControlProps={{
                  fullWidth: true,
                  className: classes.textArea,
                }}
                inputProps={{
                  multiline: true,
                  rows: 5,
                }}
              />
              <GridItem xs={12} sm={12} md={4}>
                <Box display="flex">
                  <Button type="submit" color="primary">
                    Enviar Mensagem
                  </Button>
                </Box>
              </GridItem>
            </GridContainer>
          </form>

        </GridItem>
      </GridContainer>
      <GlobalMessage onClick={() => setSnackbarOpen(false)} snackbarOpen={snackbarOpen} message={message} />
    </div>
  );
}
