import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: block;
  overflow-y: hidden;

  * {
    overflow-y: hidden !important;
  }
`;

export const SliderContainer = styled.section`
  width: 100%;
  max-width: 100%;
  height: 100vh;
  display: block;
  position: relative;
  overflow-x: hidden;

  @media (max-width: 720px) {
    height: 100vh;
  }
`;

export const BackgroundContainer = styled.div`
  width: 100%;
  height: 100vh;
  background: ${({ mobile }) => `url("${mobile}") no-repeat bottom`};
  background-size: cover;

  @media (min-width: 721px) and (max-width: 1080px) {
    background: ${({ desktop }) => `url("${desktop}") no-repeat center`}; 
    background-size: cover;
  }
`;

export const IconContainer = styled.div`
  width: 126px;
  height: 42px;

  * {
    width: 100%;
    height: 100%;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 460px;
  height: 500px;
  padding: 4rem 4rem 2rem;
  border-radius: 0.5rem;
  top: calc(100% / 1.7 - 330px);
  z-index: 2;
  background: #fff;

  ${({ translateAnimation }) => {
    if (translateAnimation) {
      return css`
        @keyframes moveUp {
          from {
            transform: translateY(100%);
          }
          to {
            transform: translateY(0);
          }
        }

        @keyframes moveDown {
          from {
            transform: translateY(0);
          }
          to {
            transform: translateY(100%);
          }
        }
      `;
    }
  }}

  @media (max-width: 1080px) {
    width: 500px;
    height: 450px;
  }

  @media (max-width: 720px) {
    padding: 2rem 2rem 1rem;
    width: 350px;
    height: 400px;
  }

  @media (min-width: 1081px) {
    left: 12rem;
  }
`;
