import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useMediaQuery, useTheme } from '@material-ui/core';

import Parallax from "components/Parallax/Parallax.js";

import styles from "assets/jss/material-kit-pro-react/views/landingPageSections/productStyle.js";

import './SectionApresentation.css';

const useStyles = makeStyles(styles);

export default function SkillsSection() {
  const classes = useStyles();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <div className='container'>
      <div style={{ marginBottom: '3rem' }}>
        <h2 className={classes.title}> Parcerias </h2>
      </div>

      <div className="grid-container">
        {isMobile ?
          <>
            <div className='parallax'>
              <Parallax
                width={isMobile ? 350 : 450}
                height={isMobile ? 350 : 450}
                image={require("assets/img/novas-imagens/_MG_5302.jpg")}
              />
            </div>
            <p className="font" style={{ width: isMobile ? '100%' : '50%' }}>Dra. Tatiana Simões  - Empresarial, Consumidor e LGPD
              Parceria de 3 anos, atuando na área de direito de consumidor e direito empresarial.
              Dra. Tatiana especialista em LGPD, Lei Geral de Proteção de Dados, exerce maestria na assessoria de empresas de pequena e médio porte.
              Juntas protagonizam um atendimento completo ao consumidor e ao empresário que necessita de um diagnóstico para evitar demandas judiciais.
            </p>
          </>
          :
          <>
            <p className="font" style={{ width: isMobile ? '100%' : '50%' }}>Dra. Tatiana Simões  - Empresarial, Consumidor e LGPD
              Parceria de 3 anos, atuando na área de direito de consumidor e direito empresarial.
              Dra. Tatiana especialista em LGPD, Lei Geral de Proteção de Dados, exerce maestria na assessoria de empresas de pequena e médio porte.
              Juntas protagonizam um atendimento completo ao consumidor e ao empresário que necessita de um diagnóstico para evitar demandas judiciais.
            </p>

            <div className='parallax'>
              <Parallax
                width={isMobile ? 350 : 450}
                height={isMobile ? 350 : 450}
                image={require("assets/img/novas-imagens/_MG_5302.jpg")}
              />
            </div>
          </>
        }

      </div>

      <div className="grid-container">
        <div className='parallax'>
          <Parallax
            width={isMobile ? 350 : 450}
            height={isMobile ? 350 : 450}
            image={require("assets/img/novas-imagens/_MG_5362.jpg")}
          />
        </div>

        <p className="font" style={{ width: isMobile ? '100%' : '50%', marginLeft: isMobile ? '0' : '1rem' }}>Dr. Felipe  – Civil, Possessórias e Contratos.
          Parceria de 5 anos, atuando com assessoria na cidade de Jaraguá do Sul.
          Dr.Felipe, especialista em direito civil, com uma longa experiência na área de ações possessórias como usucapião e regularização de imóveis em todo o estado de Santa Catarina, com escritório na cidade de Jaraguá do Sul e Barra do Sul.
          Ambos protagonizaram inúmeros processos já finalizados de sucesso, priorizando a troca de experiências para análise profunda dos processos.
        </p>
      </div>

      <div className="grid-container">
        {isMobile ?
          <>

            <div className='parallax'>
              <Parallax
                width={isMobile ? 350 : 450}
                height={isMobile ? 350 : 450}
                image={require("assets/img/novas-imagens/_MG_5310.jpg")}
              />
            </div>
            <p className="font" style={{ width: isMobile ? '100%' : '50%', marginLeft: isMobile ? '0' : '1rem' }}>Dra. Ellen Erching – Direito do Trabalho
              Parceria de 6 anos, atuando em processos do trabalho.
              Dra Ellen especialista em Direito do Trabalho, com experiência e pós-graduada na área, para agir em prol do trabalhador e assessorando empresas na forma preventiva e defesa de ações trabalhistas.
            </p>
          </> :
          <>
            <p className="font" style={{ width: isMobile ? '100%' : '50%', marginLeft: isMobile ? '0' : '1rem' }}>Dra. Ellen Erching – Direito do Trabalho
              Parceria de 6 anos, atuando em processos do trabalho.
              Dra Ellen especialista em Direito do Trabalho, com experiência e pós-graduada na área, para agir em prol do trabalhador e assessorando empresas na forma preventiva e defesa de ações trabalhistas.
            </p>

            <div className='parallax'>
              <Parallax
                width={isMobile ? 350 : 450}
                height={isMobile ? 350 : 450}
                image={require("assets/img/novas-imagens/_MG_5310.jpg")}
              />
            </div>
          </>
        }

      </div>

      <div className="grid-container">
        <div className='parallax'>
          <Parallax
            width={isMobile ? 350 : 450}
            height={isMobile ? 350 : 450}
            image={require("assets/img/novas-imagens/_MG_5368.jpg")}
          />
        </div>

        <p className="font" style={{ width: isMobile ? '100%' : '50%', marginLeft: isMobile ? '0' : '1rem' }}>Dra. Melati e Paulo Kitamura – Previdenciário
          Parceria de 4 anos, atuando em processos previdenciários.
          Dra Melati e Dr Paulo, trabalham em conjunto no atendimento de processos previdenciários, somando juntos 20 anos de experiência técnica na área junto ao INSS. O atendimento vai além de análise de tempo de serviço, cálculos, ações previdenciários, acampamento em perícia e regularização de aposentadorias, abrangendo todos os campos da previdência.
        </p>
      </div>
    </div>
  );
}
