import React from "react";
import { Snackbar } from "@material-ui/core";

export default function GlobalMessage({ onClick, snackbarOpen, message }) {
  const handleSnackbarClose = () => {
    onClick();
  };

  return (
    <Snackbar
      open={snackbarOpen}
      autoHideDuration={4000}
      onClose={handleSnackbarClose}
      message={message.message}
    />
  );
}
