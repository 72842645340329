import React, { Component } from "react";

class LocationSection extends Component {
  render() {
    return (
      <div style={{ height: "100%", width: "100%" }}>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5206.463779198853!2d-48.8533808598815!3d-26.30317882377889!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94deb10b6ca72c77%3A0xbbb037798208d695!2sAna%20Carolina%20Lucena%20-%20Advocacia%20e%20Assessoria!5e0!3m2!1spt-BR!2sbr!4v1599163042442!5m2!1spt-BR!2sbr"
          width="100%"
          height="650"
          frameBorder="0"
          allowFullScreen=""
          aria-hidden="false"
          tabIndex="0"
        />
      </div>
    );
  }
}

export default LocationSection;
