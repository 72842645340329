import React from "react";
import Slider from "react-slick";

import CarrouselLogin from "../../../src/assets/img/novas-imagens/image2.jpeg";
import CarrouselLogin1 from "../../../src/assets/img/novas-imagens/image4.jpeg";
import CarrouselLogin2 from "../../../src/assets/img/novas-imagens/4.jpg";
import CarrouselLogin3 from "../../../src/assets/img/novas-imagens/image0.jpeg";
import CarrouselLogin4 from "../../../src/assets/img/novas-imagens/image1.jpeg";

import * as Styles from "./styles";

export const slideItens = [
  { mobile: CarrouselLogin, desktop: CarrouselLogin },
  {
    mobile: CarrouselLogin1,
    desktop: CarrouselLogin1,
  },
  {
    mobile: CarrouselLogin2,
    desktop: CarrouselLogin2,
  },
  {
    mobile: CarrouselLogin3,
    desktop: CarrouselLogin3,
  },
  {
    mobile: CarrouselLogin3,
    desktop: CarrouselLogin3,
  },
  {
    mobile: CarrouselLogin4,
    desktop: CarrouselLogin4,
  },
];

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  autoplay: true,
  autoplaySpeed: 5000,
  pauseOnHover: false,
};

const SliderComponent = () => {
  const sliderRef = React.useRef<any>(null);

  return (
    <>
      <Styles.Container>
        <Styles.SliderContainer>
          <Slider ref={sliderRef} {...settings}>
            {slideItens.map((images, index) => (
              <Styles.BackgroundContainer
                key={`${images}_${index}`}
                data-testid={`slide_page_${index}`}
                {...images}
              />
            ))}
          </Slider>
        </Styles.SliderContainer>
      </Styles.Container>
    </>
  );
};

export default SliderComponent;
