/*eslint-disable*/ import React from 'react';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// nodejs library that concatenates classes
import classNames from 'classnames';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import { useMediaQuery, useTheme } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
// @material-ui/icons
import Favorite from '@material-ui/icons/Favorite';
// core components
import Header from 'components/Header/Header.js';
import Footer from 'components/Footer/Footer.js';
import HeaderLinks from 'components/Header/HeaderLinks.js';

import landingPageStyle from 'assets/jss/material-kit-pro-react/views/landingPageStyle.js';

// Sections for this page
import SectionApresentation from './Sections/SectionApresentation';
import SectionSkills from './Sections/SectionSkills.js';
import SectionLocation from './Sections/SectionLocation.js';
import SectionContact from './Sections/SectionContact.js';
import SectionPartners from './Sections/SectionPartners.js';
import './index.css';

import Slider from 'components/Slider/index';

import whatsappIcon from "../../assets/img/novas-imagens/whatsappIcon.png";

const useStyles = makeStyles(landingPageStyle);

function reveal() {
  var reveals = document.querySelectorAll(".reveal-partners");

  for (var i = 0; i < reveals.length; i++) {
    var windowHeight = window.innerHeight;
    var elementTop = reveals[i].getBoundingClientRect().top;
    var elementVisible = 150;

    if (elementTop < windowHeight - elementVisible) {
      reveals[i].classList.add("active");
    } else {
      reveals[i].classList.remove("active");
    }
  }
}

function revealSkills() {
  var reveals = document.querySelectorAll(".reveal-skills");

  for (var i = 0; i < reveals.length; i++) {
    var windowHeight = window.innerHeight;
    var elementTop = reveals[i].getBoundingClientRect().top;
    var elementVisible = 150;

    if (elementTop < windowHeight - elementVisible) {
      reveals[i].classList.add("active");
    } else {
      reveals[i].classList.remove("active");
    }
  }
}

window.addEventListener("scroll", reveal);
window.addEventListener("scroll", revealSkills);

export default function LandingPage({ ...rest }) {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const classes = useStyles();

  const rollPageOnClickButton = (nameDiv) => {
    document.getElementById(nameDiv).scrollIntoView({ behavior: "smooth" });
  };

  const handleNavigateWhatsapp = () => {
    window.open('https://wa.me/554792301776?text=Olá,%20Carol%20gostaria%20de%20informações%20sobre%20seu%20trabalho', '_blank');
  };

  return (
    <div>
      <Header
        color="transparent"
        brand={
          isMobile
            ? 'Ana Carolina Lucena'
            : 'Ana Carolina Lucena - Advocacia e Assessoria'
        }
        links={<HeaderLinks dropdownHoverColor="info" rollPageOnClickButton={rollPageOnClickButton} />}
        rollPageOnClickButton={rollPageOnClickButton}
        fixed
        changeColorOnScroll={{
          height: 300,
          color: 'white',
        }}
        {...rest}
      />
      <div id='home'>
        <Slider />
      </div>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>

          <div id='apresentation'>
            <SectionApresentation />
          </div>
          <div id="skills" className='reveal-skills'>
            <SectionSkills />
          </div>
          <div id="partners" className='reveal-partners'>
            <SectionPartners />
          </div>
          <div id="location">
            <SectionLocation />
          </div>
          <div id="contact">
            <SectionContact />
          </div>
        </div>
      </div>
      <Footer
        content={
          <div>
            <div className={classes.left}>
              <List className={classes.list}>
                <ListItem className={classes.inlineBlock}>
                  <a
                    href=""
                    className={classes.block}
                  >
                    Brothers Dev
                  </a>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <a
                    href=""
                    className={classes.block}
                  >
                    Sobre nós
                  </a>
                </ListItem>
              </List>
            </div>
            <div className={classes.right}>
              &copy; {1900 + new Date().getYear()} , Feito com
              <Favorite className={classes.icon} style={{ marginLeft: '8px' }} /> by{' '}
              <a
                href=""
              >
                Brothers Dev
              </a>
            </div>
          </div>
        }
      />

      <div style={{ display: 'flex', position: 'fixed', zIndex: '99999999', bottom: 0, right: 0, padding: '16px', cursor: 'pointer' }}>
        <img src={whatsappIcon} alt="" onClick={handleNavigateWhatsapp} />
      </div>
    </div>
  );
}
