import styled, { css } from 'styled-components';

export const Container = styled.div`
  @media (min-width: 1081px) {
    width: 100%; 
    justify-content: flex-end; 
    display: flex;
  }
`;

export const WrapperIcons = styled.div`
 @media (min-width: 1081px) {
    margin-top: 0.5rem;
  }
`;